<template>
  <div class="z-date-picker" @click="showCalendar">
    <z-icon name="Calendar" size="16" />
    <date-picker
      :value="value"
      @input="$emit('input', $event)"
      @click.native="showCalendar"
      wrapper-class="date-picker-wrapper"
      calendar-class="date-picker-calendar"
      :language="language"
      input-class="date-picker-input"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-on="$listeners"
      ref="dateInput"
    />
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import ZIcon from "./ZIcon";

export default {
  name: "ZDatePicker",

  components: {
    DatePicker,
    ZIcon
  },

  props: {
    value: {},
    placeholder: {
      type: String,
      default: "Fecha"
    }
  },

  data() {
    return {
      customDateFilter: null,
      language: es
    };
  },

  methods: {
    showCalendar() {
      this.$refs.dateInput.showCalendar();
    }
  }
};
</script>

<style lang="scss">
@import "../styles/variables/colors.scss";

.date-picker-wrapper {
  width: 100%;
}

.date-picker-calendar {
  border-radius: 3.5px;
  color: $nero;

  .day__month_btn,
  .day-header {
    font-weight: 500;
    font-size: 14px !important;
  }

  .cell:hover {
    background-color: $solitude !important;
    border-radius: 3.5px;
    border: 1px solid transparent !important;
  }

  .cell.selected {
    background-color: $zircon !important;
    color: $zubut-blue;
    border-radius: 3.5px;
    text-shadow: 0 -1px 0 rgba($black, 0.25);

    &:hover {
      background-color: $zircon;
    }
  }
}

.date-picker-input {
  border: none;
  cursor: pointer;
  padding-left: 16px;
  width: 100%;
  border-radius: 6px;
  padding-right: 8px;
}
</style>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.z-date-picker {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 2.4em;
  margin: 0;
  padding-right: 8px;
  padding-left: 8px;
  background-color: $white;
  border: 1px solid $link-water;
  border-radius: 6px;
  font-weight: 500;
  box-sizing: border-box;
  color: $comet;
  font-size: $font-size-sm;
  cursor: pointer;

  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    font-size: 1.4em;
    color: #1e1e1e;
  }
}

.z-date-picker:focus {
  border: 1px solid $zubut-blue;
  border-radius: 6px;
  box-shadow: 0px 0px 2px rgba($zubut-blue, 0.25);
}
</style>
