import Vue from "vue";

// @SECTION: implementation

const HANDLER = "_vue_clickaway_handler";

function bind(el: any, binding: any, vnode: any) {
  unbind(el, binding);

  const vm = vnode.context;

  const callback = binding.value;
  if (typeof callback !== "function") {
    if (process.env.NODE_ENV !== "production") {
      Vue.util.warn(
        "v-" +
          binding.name +
          '="' +
          binding.expression +
          '" expects a function value, ' +
          "got " +
          callback
      );
    }
    return;
  }

  // @NOTE: Vue binds directives in microtasks, while UI events are dispatched
  //        in macrotasks. This causes the listener to be set up before
  //        the "origin" click event (the event that lead to the binding of
  //        the directive) arrives at the document root. To work around that,
  //        we ignore events until the end of the "initial" macrotask.
  // @REFERENCE: https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/
  // @REFERENCE: https://github.com/simplesmiler/vue-clickaway/issues/8
  let initialMacrotaskEnded = false;
  setTimeout(function() {
    initialMacrotaskEnded = true;
  }, 0);

  el[HANDLER] = function(ev: any) {
    // @NOTE: this test used to be just `el.contains`, but working with path is better,
    //        because it tests whether the element was there at the time of
    //        the click, not whether it is there now, that the event has arrived
    //        to the top.
    // @NOTE: `.path` is non-standard, the standard way is `.composedPath()`
    const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined);
    if (
      initialMacrotaskEnded &&
      (path ? path.indexOf(el) < 0 : !el.contains(ev.target))
    ) {
      return callback.call(vm, ev);
    }
  };

  if (binding.arg) {
    document.documentElement.addEventListener(binding.arg, el[HANDLER], false);
  } else {
    // default state, if no argument is passed
    document.documentElement.addEventListener("click", el[HANDLER], false);
  }
}

function unbind(el: any, binding: any) {
  if (binding.arg) {
    document.documentElement.removeEventListener(
      binding.arg,
      el[HANDLER],
      false
    );
  } else {
    // default state, if no argument is passed
    document.documentElement.removeEventListener("click", el[HANDLER], false);
  }

  delete el[HANDLER];
}

export default {
  bind: bind,
  update: function(el: any, binding: any, vnode: any) {
    if (binding.value === binding.oldValue) return;
    bind(el, binding, vnode);
  },
  unbind: unbind
};
