<template>
  <div
    v-click-outside="handleClickaway"
    class="z-input-address-wrapper"
    id="input-address"
  >
    <z-input
      class="z-input-address"
      :value="value"
      @input="$emit('input', $event)"
      v-bind="$attrs"
      :autocomplete="false"
      :append-icon="appendIcon"
      :append-icon-variant="appendIconVariant"
      append-icon-tootltip="Guardar en favoritos"
      @append-icon-click="handleSaveAddress"
      @focus="isFocused = true"
      @keydown="handleArrowKeys"
      @keydown.tab="isFocused = false"
      @keydown.enter="handleSuggestionClick()"
    >
    </z-input>
    <ul v-show="showSuggestions" class="autocomplete-menu">
      <li
        :class="{ 'is-focus': selectedItem === index }"
        v-for="(place, index) in searchResults"
        :key="`plaza-${index}`"
        role="button"
        @click="handleSuggestionClick(place)"
      >
        <b>{{ place.structured_formatting.main_text }}</b>
        {{ place.structured_formatting.secondary_text }}
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from "@zubut/common/src/directives/click-outside";
import ZInput from "./ZInput";
import _debounce from "lodash/debounce";

export default {
  name: "ZInputAddress",

  components: {
    ZInput
  },

  directives: {
    clickOutside: vClickOutside
  },

  props: {
    value: {
      type: String,
      default: ""
    },
    addressSaved: {
      type: Boolean,
      default: false
    },
    allowSave: {
      type: Boolean,
      deafult: true
    },
    options: {
      type: Object,
      default: () => ({
        componentRestrictions: { country: ["mx", "co"] },
        strictBounds: true
      })
    },
    placeholder: {
      type: String,
      default: ""
    },
    selectFirstOnEnter: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      autocompleteService: null,
      placesService: null,
      searchResults: [],
      isFocused: false,
      selectedItem: null
    };
  },

  computed: {
    appendIcon() {
      if (!this.allowSave) return "";
      return this.addressSaved ? "FavoriteSaved" : "FavoriteAdd";
    },
    appendIconVariant() {
      return this.addressSaved ? "active" : "normal";
    },
    addressText() {
      return this.address ? this.address.address : this.value;
    },
    showSuggestions() {
      return this.isFocused && this.searchResults.length > 0;
    }
  },

  watch: {
    value() {
      this.getPredictions();
    }
  },

  created() {
    this.loadMapsApi();
    this.getPredictions = _debounce(this.getPredictions, 300);
  },

  methods: {
    handleFocus() {
      this.isFocused = true;
    },

    loadMapsApi() {
      if (!window?.google?.maps?.places) {
        this.$loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_JAVASCRIPT_KEY}&libraries=places&language=es_MX`
        ).then(() => {
          this.autocompleteService = new window.google.maps.places.AutocompleteService();
          this.placesService = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
        });
      } else {
        this.autocompleteService = new window.google.maps.places.AutocompleteService();
        this.placesService = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
      }
    },

    getPredictions() {
      this.selectedItem = null;
      if (this.value && this.autocompleteService) {
        this.autocompleteService.getPlacePredictions(
          {
            ...this.options,
            input: this.value
          },
          this.displaySuggestions
        );
      }
    },

    handleArrowKeys(event) {
      this.isFocused = true;
      switch (event.keyCode) {
        case 38: // Up arrow
          if (this.selectedItem === null) {
            this.selectedItem = 0;
          }
          if (this.selectedItem > 0) this.selectedItem--;
          break;
        case 40: // Down arrow
          if (this.selectedItem === null) {
            this.selectedItem = 0;
          } else if (this.selectedItem < this.searchResults.length)
            this.selectedItem++;
          break;
      }
    },

    handleSuggestionClick(place) {
      if (!place) {
        place = this.searchResults[this.selectedItem];
      }
      this.isFocused = true;
      if (place?.place_id) {
        this.placesService.getDetails(
          {
            placeId: place.place_id,
            fields: ["formatted_address", "geometry.location"]
          },
          res => {
            this.setPlace(res);
          }
        );
      }
    },

    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      this.searchResults = predictions;
    },

    setPlace(place) {
      if (place.geometry) {
        const newAddress = {
          address: place.formatted_address,
          position: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          },
          valid: true
        };
        this.$emit("selected", newAddress);
      } else {
        const newAddress = {
          address: place.name,
          position: null,
          valid: false
        };
        this.$emit("selected", newAddress);
      }
      this.isFocused = false;
    },
    handleSaveAddress() {
      this.$emit("save");
    },
    handleClickaway() {
      this.isFocused = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.z-input-address-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
}

.autocomplete-menu {
  position: absolute;
  background-color: $white;
  top: 36px;
  left: 0;
  box-shadow: 0 3px 6px 0 rgba($black, 0.16);
  border-radius: 8px;
  padding: 0;
  z-index: 2001;
  font-size: 12px;
  max-width: 100%;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      background-color: $solitude-100;
    }

    &.is-focus {
      background: $solitude-100;
    }
  }
}
</style>
